import axios from 'axios';
import { getCookie, putCookie } from './cookie.helper';
import { IProject, IDeployment, IService, IFeatureBranch, IBranchData, IStartTestResponse } from '../interfaces';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API!,
});

instance.interceptors.request.use(
  async (config) => {
    config.headers = {
      authorization: getCookie('JWT'),
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.response?.status === 403) {
      alert('JWT expired');
      putCookie('redirect_to', window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        path: '/',
      });
      window.location.href = process.env.REACT_APP_AUTH_PATH!;
      return;
    }
    return Promise.reject(error);
  }
);

export const api = {
  project: {
    list: () => {
      return instance.get<IProject[]>('/project');
    },
    create: (display_name: string, namespace: string) => {
      return instance.put<IProject>('/project/', {
        display_name,
        namespace,
      });
    },
    edit: (id: string, display_name: string, namespace: string) => {
      return instance.patch<IProject>('/project/' + id, {
        display_name,
        namespace,
      });
    },
    delete: (id: string) => {
      return instance.delete('/project/' + id);
    },
  },
  deployment: {
    list: (project_id: string) => {
      return instance.get<{ deployments: IDeployment[]; project_name: string }>('/deployment/' + project_id);
    },
    create: (project_id: string, name: string, display_name: string, database: string | null) => {
      // TODO: set git_url in UI
      return instance.put<IDeployment>('/deployment/', { project_id, name, display_name, git_url: '', database });
    },
    edit: (id: string, name: string, display_name: string, database: string | null) => {
      return instance.patch<IDeployment>('/deployment/' + id, { name, display_name, database });
    },
    delete: (id: string) => {
      return instance.delete('/deployment/' + id);
    },
  },
  service: {
    list: (deployment_id: string) => {
      return instance.get<{ services: IService[]; deployment_name: string }>('/service/' + deployment_id);
    },
    nodeSelectorList: () => {
      return instance.get<string[]>('/service/nodeSelectors');
    },
    create: (deployment_id: string, env: string) => {
      return instance.put<IService>('/service/', { deployment_id, env });
    },
    edit: (id: string, data: { env?: string; image?: string; config?: IService['config'] }) => {
      return instance.patch<IService>('/service/' + id, data);
    },
    delete: (id: string) => {
      return instance.delete('/service/' + id);
    },
  },
  deploy: {
    service: (service_id: string) => {
      return instance.post<any>('/helm-upgrade/service/' + service_id);
    },
  },
  feature_branch: {
    list: () => {
      return instance.get<IFeatureBranch>('/feature-branches/list');
    },
    changeStatus: (feature_branch_name: string, status: 'start' | 'stop' | 'clean') => {
      return instance.post<any>(`/feature-branches/${status}`, { env: feature_branch_name });
    },
    show: (branch_id: string) => {
      return instance.get<IBranchData[]>(`/feature-branches/show/${branch_id}`);
    },
  },
  cypress_test: {
    start_diff: (branch_id: string) => {
      return instance.post<IStartTestResponse>(`cypress-test/start-diff/${branch_id}`);
    },
    start_full: (branch_id: string) => {
      return instance.post<IStartTestResponse>(`cypress-test/start-full/${branch_id}`);
    },
    stop: (test_id: string) => {
      return instance.post<{ affected: number }>(`cypress-test/stop/${test_id}`);
    },
  },
} as const;
