import { Settings } from '@mui/icons-material';
import { Alert, TabContext, TabList, TabPanel } from '@mui/lab';
import { AlertColor, Box, Snackbar, Tab } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EUserPermissions } from '../../app/enums';
import { withAccess } from '../../auth-context/can-access';
import { api } from '../../helpers/api.helper';
import { IService } from '../../interfaces';
import { DropdownMenu } from '../dropdown-menu/dropdown-menu';
import { EditableDialog, EditableDialogInput, TEditableDialogInputs } from '../editable-dialog/editable-dialog';
import { Header } from '../header/header';
import { ServiceConfig } from './service-config';
import { ServiceContext } from './service-context';

const ServiceComponent = () => {
  const { project_id, deployment_id } = useParams<{ project_id: string; deployment_id: string }>();

  const [availableNodeSelectorList, setAvailableNodeSelectorList] = useState<string[] | null>(null);

  const [currentDeploymentName, setCurrentDeploymentName] = useState('');
  const [services, setServices] = useState<IService[] | null>(null);

  const [withEditModal, setWithEditModal] = useState(false);
  const [serviceData, setServiceData] = useState<IService | null>(null);

  const [editingService, setEditingService] = useState<IService | null>(null);
  const [alert, setAlert] = useState<{ type: AlertColor | undefined; message: string } | null>(null);

  const editingServiceRef = useRef<IService>();

  const setEditingServiceWithRef = (service: IService | null) => {
    if (service) {
      editingServiceRef.current = JSON.parse(JSON.stringify(service));
    } else {
      editingServiceRef.current = undefined;
    }

    setEditingService(service);
  };

  const handleChange = (_: unknown, newValue: string) =>
    setEditingServiceWithRef(services!.find((service) => service.env === newValue) || null);

  useEffect(() => {
    api.service.list(deployment_id).then(({ data: { services, deployment_name } }) => {
      setServices(services);

      if (services.length) {
        setEditingServiceWithRef(services[0]);
      }

      setCurrentDeploymentName(deployment_name);
    });

    api.service.nodeSelectorList().then(({ data }) => setAvailableNodeSelectorList(data));
  }, []);

  if (!availableNodeSelectorList || !services) {
    return <></>;
  }

  const isServiceEdit = serviceData?.id;

  const inputs = [
    {
      title: 'Env',
      key: 'env',
      default_value: serviceData?.env || '',
    },
    // eslint-disable-next-line prettier/prettier
  ] as const satisfies Readonly<EditableDialogInput<keyof IService>[]>;

  const editService = (id: string, data: { env?: string; image?: string; config?: IService['config'] }) => {
    api.service
      .edit(id, data)
      .then((v) => {
        if (v.data.id) {
          setServices((services) =>
            services!.map((s) => {
              if (s.id === v.data.id) {
                const next = { ...s, ...v.data };

                setEditingServiceWithRef(next);

                setAlert({
                  type: 'success',
                  message: `Successfully saved for ${next.env} env!`,
                });

                return next;
              }
              return s;
            })
          );
        }
      })
      .catch((v) => {
        if (v.response?.data) {
          setAlert({
            type: 'error',
            message: JSON.stringify(v.response.data),
          });
        }
      });
  };

  const createService = (env: string) => {
    api.service.create(deployment_id, env).then((v) => {
      if (v.data.id) {
        if (!services.length) {
          setEditingServiceWithRef(v.data);
        }

        setServices((services) => services!.concat(v.data));
      }
    });
  };

  const deleteService = (id: string) => {
    api.service.delete(id).then(() => {
      if (services.length === 1) {
        setEditingServiceWithRef(null);
      }

      setServices((services) => services!.filter((service) => service.id !== id));
    });
  };

  const onEditModalOk = (obj: TEditableDialogInputs<typeof inputs>) => {
    if (isServiceEdit) {
      editService(serviceData.id, { env: obj.env });
    } else {
      createService(obj.env);
    }

    setServiceData(null);
    setWithEditModal(false);
  };

  const onEditModalCancel = () => setWithEditModal(false);

  const openEditModal = (data: IService | null) => {
    setServiceData(data);
    setWithEditModal(true);
  };

  const saveServiceRef = () => {
    const ref = editingServiceRef.current!;
    editService(ref.id, { image: ref.image, config: ref.config });
  };

  return (
    <>
      <Header
        title={currentDeploymentName}
        mainBtnHandler={() => saveServiceRef()}
        isSave
        backLink={`/projects/${project_id}`}
        createHandler={() => openEditModal(null)}
        children={
          !!editingService && (
            <DropdownMenu
              buttonElement={<Settings />}
              items={[
                { text: 'Edit', onClick: () => openEditModal(editingService) },
                { text: 'Delete', onClick: () => deleteService(editingService.id) },
              ]}
            />
          )
        }
      />
      <ServiceContext.Provider value={{ editingService: editingServiceRef.current! }}>
        <Box className="box service-box">
          {editingService ? (
            <TabContext value={editingService.env}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList variant="fullWidth" onChange={handleChange} textColor="primary" indicatorColor="primary">
                  {services.map(({ env }) => (
                    <Tab key={env} label={env} value={env} />
                  ))}
                </TabList>
              </Box>
              {services.map((service) => (
                <TabPanel key={service.env} value={service.env} className="tab-panel">
                  <ServiceConfig availableNodeSelectorList={availableNodeSelectorList} />
                </TabPanel>
              ))}
            </TabContext>
          ) : (
            <div className="box__empty">
              <p className="box__link">No envs</p>
            </div>
          )}
        </Box>
      </ServiceContext.Provider>
      <EditableDialog
        open={withEditModal}
        header={isServiceEdit ? 'Edit service' : 'Create service'}
        inputs={inputs}
        onOk={onEditModalOk}
        onCancel={onEditModalCancel}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={6000}
        open={!!alert}
        onClose={() => setAlert(null)}
      >
        <Alert onClose={() => setAlert(null)} severity={alert?.type}>
          {alert?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export const ServicePage = withAccess(EUserPermissions.PROJECTS)(ServiceComponent);
