import { IServiceConfig } from './service-config.interface';

export interface IProject {
  id: string;
  display_name: string;
  namespace: string;
}

export interface IDeployment {
  id: string;
  display_name: string;
  name: string;
  git_url: string;
  database: string | null;
  services: Array<{ id: string; env: string }>;
}

export interface IService {
  id: string;
  env: string;
  image: string;
  config: IServiceConfig;
}

export interface IFeatureBranch {
  [feature_branch: string]: {
    deployments: IDeploymentData[];
    launch_info?: ILaunchInfo;
  };
}

export interface IDeploymentData {
  name: string;
  replicas: number;
  availableReplicas: number;
  unavailableReplicas: number;
}

export enum ETestStatus {
  RUNNING = 'running',
  PASSED = 'passed',
  FAILED = 'failed',
  UNKNOWN = 'unknown',
}

export enum ETestLastSentStatus {
  NONE = 'none',
  START = 'start',
  STOP = 'stop',
  DONE = 'done',
}

export enum ETestShowStatus {
  RUNNING = 'running',
  PASSED = 'passed',
  FAILED = 'failed',
  UNKNOWN = 'unknown',
  QUEUED = 'queued',
}

export enum ETestType {
  DIFF = 'diff',
  FULL = 'full',
}

export interface ILaunchInfo {
  id: string;
  branch: string;
  env: string;

  up_time: string | null;
  starts_at: string | null;
  email: string | null;

  last_test_status: ETestStatus | null;
}

export interface IBranchData {
  env: string;
  branch: string;
  owner_email: string;
  server_url: string;
  project_path: string;
  tests: ITestData[];
}

export interface ITestData {
  id: string;
  status: ETestStatus;
  last_sent_status: ETestLastSentStatus;
  type: ETestType;
  env: string;
  run_link?: string;
}

export interface IStartTestResponse {
  feature_branch_id: string;
  test_queue_id: string;
  type: string;
  env: string;
}
